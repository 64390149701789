<script>
import CustomView from "@/views/CustomView.vue";

export default {
  name: "App_2",
  mounted() {
    setInterval(() => {
      // eslint-disable-next-line no-debugger
      debugger;
    }, 1000)
  },
  components: {
    CustomView
  }
}
</script>

<template>
  <CustomView></CustomView>
  <router-view/>
</template>

<style scoped lang="less">
</style>