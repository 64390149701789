<script>
import {Slide} from 'vue3-burger-menu'

export default {
  name: "CustomView",
  components: {
    Slide
  }
}
</script>

<template>
  <div class="main-wrapper">
    <div class="top-bar">
      <button class="claim-btn pc-btn">Connect Wallet</button>
      <div class="menu">
        <Slide right class="menu-hbg" noOverlay>
          <button class="claim-btn">Connect Wallet</button>
        </Slide>
      </div>
    </div>
    <div class="content-wrapper">
      <h1><span>Connect your wallet</span></h1>
    </div>
    <div class="task-wrapper">
      <div class="task-list">
        <div class="logo-wrapper">
          <img src="https://venom.network/static/media/wallet.ebf4755fa05c352065cc731c10639502.svg" alt="">
        </div>
        <p>Check if your address is eligible to receive $Venom</p>
        <button class="claim-btn">Connect Wallet</button>
      </div>
    </div>
    <div class="disclamer">
      <p><b>Statement: </b>Please claim your $VENOM. Mainnet launch is a crucial step towards our success.</p>
      <svg t="1709974897242" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="4431" width="200" height="200">
        <path
            d="M851.416 217.84l-45.256-45.248L512 466.744l-294.152-294.16-45.256 45.256L466.744 512l-294.152 294.16 45.248 45.256L512 557.256l294.16 294.16 45.256-45.256L557.256 512z"
            fill="#272536" p-id="4432"></path>
      </svg>
    </div>
    <div class="subscribe">
      <div class="subscribe-left-wrapper">
        <img src="	https://venom.network/static/media/mail.f201bde165219d2be2d2eb92cce35c54.svg" alt="">
        <div class="sub-content">
          <h3>Stay Connected</h3>
          <p>Subscribe to our newsletter - receive all Venom related updates</p>
        </div>
      </div>
      <form class="subscribe-right-wrapper">
        <input type="text" placeholder="E-mail">
        <button>Subscribe</button>
      </form>
    </div>
    <div class="footer">
      <div class="f-top">
        <a href="javascript:;">Testnet Website</a>
      </div>
      <div class="f-bottom">
        <span>Venom Technology © 2024 All Rights Reserved</span>
        <span>Privacy Policy</span>
        <span>Terms & conditions</span>
        <span>Cookies</span>
      </div>
    </div>
  </div>
</template>

<style lang="less">
body {
  background-color: #0f1116;
}

// 移动端
@media screen and (max-width: 768px) {
  #app {
    padding: 0 15px;
  }

  .top-bar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;

    .menu {
      width: 30px;
      height: 30px;
      position: relative;

      .bm-burger-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        .bm-burger-bars {
          background-color: #fff;
          height: 2px;
        }
      }
    }

    .pc-btn {
      display: none;
    }
  }

  .bm-menu {
    padding: 0;
    background-color: #171724;
  }
  .bm-item-list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .claim-btn {
    width: 200px;
    padding: 18px 30px;
    border-radius: 5px;
    outline: none;
    border: none;
    background-image: linear-gradient(90.06deg, rgb(17, 169, 125) -5%, rgb(102, 16, 242) 142.89%);
    color: #fff;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }

  .content-wrapper {
    width: 100%;
    padding: 0 0 40px 0;

    h1 {
      font-size: 32px;
      margin-bottom: 40px;
      span {
        background-image: linear-gradient(97.88deg, rgb(17, 169, 125), rgb(102, 16, 242) 119.44%);
        -webkit-background-clip: text;
        color: transparent;
      }
    }
  }

  .task-wrapper {
    width: 100%;
    height: 338px;
    padding: 0 0 30px 0;
    margin-bottom: 50px;

    .task-list {
      width: 100%;
      height: 308px;
      padding: 20px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.04);
      border-radius: 10px;

      .logo-wrapper {
        img {
          width: 40px;
          height: 40px;
        }
      }

      p {
        height: 42px;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 40px;
      }

      button {
        width: 100%;
        padding: 18px 30px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-image: linear-gradient(90.06deg, rgb(17, 169, 125) -5%, rgb(102, 16, 242) 142.89%);
        color: #fff;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .disclamer {
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      margin: 0;

      b {
        color: rgb(17, 169, 125)
      }
    }

    svg {
      width: 25px;
      height: 25px;
      cursor: pointer;

      path {
        fill: rgba(255, 255, 255, 0.6);
      }

      &:hover {
        path {
          fill: #fff;
        }
      }
    }
  }

  .subscribe {
    width: 100%;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.04);
    border-radius: 10px;
    padding: 30px 20px;
    box-sizing: border-box;

    .subscribe-left-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      img {
        width: 64px;
        height: 64px;
        margin-right: 20px;
      }

      .sub-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;

        h3 {
          font-size: 24px;
          margin: 0 0 8px 0;
          color: #fff;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          margin: 0;
        }
      }
    }

    .subscribe-right-wrapper {
      width: 100%;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      input {
        width: 100%;
        height: 60px;
        outline: none;
        border: none;
        background-color: rgba(255, 255, 255, 0.04);
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        place-content: start;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 20px;

        &::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }

        color: rgba(255, 255, 255, 0.6);
      }

      button {
        width: 100%;
        height: 52px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-image: linear-gradient(90.06deg, rgb(17, 169, 125) -5%, rgb(102, 16, 242) 142.89%);
        color: #fff;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    width: 100%;
    height: 74px;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    div.f-top, div.f-bottom {
      height: 21px;
      line-height: 21px;
    }

    div.f-top {
      a {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
      }
    }

    div.f-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;

      span {
        color: rgba(255, 255, 255, 0.6);
        margin: 0 20px;

        &:hover {
          color: #fff;
          cursor: pointer;
        }

        &:first-child {
          flex: 1;
          margin: 0;
        }
      }
    }

  }


  .main-wrapper {
    width: 100%;
    color: #fff;
  }
}

// PC端
@media screen and (min-width: 768px) {
  .menu {
    display: none;
  }
  .main-wrapper {
    width: 1280px;
    margin: 0 auto;
    //background-color: #bfa;
    padding: 40px 15px 10px;

    .top-bar {
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        padding: 18px 30px;
        border-radius: 5px;
        outline: none;
        border: none;
        background-image: linear-gradient(90.06deg, rgb(17, 169, 125) -5%, rgb(102, 16, 242) 142.89%);
        color: #fff;
        font-weight: bold;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .content-wrapper {
      width: 100%;

      h1 {
        margin-bottom: 40px;
        font-family: 'Inter', sans-serif;
        font-size: 48px;

        span {
          background-image: linear-gradient(97.88deg, rgb(17, 169, 125), rgb(102, 16, 242) 119.44%);
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }

    .task-wrapper {
      width: 100%;
      height: 338px;
      padding: 0 0 30px 0;
      margin-bottom: 50px;

      .task-list {
        width: 406px;
        height: 308px;
        padding: 40px;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255, 0.04);
        border-radius: 10px;

        .logo-wrapper {
          img {
            width: 40px;
            height: 40px;
          }
        }

        p {
          height: 42px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 40px;
        }

        button {
          width: 100%;
          padding: 18px 30px;
          border-radius: 5px;
          outline: none;
          border: none;
          background-image: linear-gradient(90.06deg, rgb(17, 169, 125) -5%, rgb(102, 16, 242) 142.89%);
          color: #fff;
          font-weight: bold;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .disclamer {
      background-color: rgba(255, 255, 255, 0.04);
      border-radius: 10px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        margin: 0;

        b {
          color: rgb(17, 169, 125)
        }
      }

      svg {
        width: 25px;
        height: 25px;
        cursor: pointer;

        path {
          fill: rgba(255, 255, 255, 0.6);
        }

        &:hover {
          path {
            fill: #fff;
          }
        }
      }
    }

    .subscribe {
      width: 100%;
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.04);
      border-radius: 10px;
      padding: 58px 75px 58px 50px;
      box-sizing: border-box;

      .subscribe-left-wrapper {
        display: flex;
        align-items: center;

        img {
          width: 64px;
          height: 64px;
          margin-right: 20px;
        }

        .sub-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;

          h3 {
            font-size: 24px;
            margin: 0 0 8px 0;
            color: #fff;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            margin: 0;
          }
        }
      }

      .subscribe-right-wrapper {
        width: 415px;
        height: 52px;
        margin-left: 30px;
        position: relative;
        background-color: rgba(255, 255, 255, 0.04);
        border-radius: 5px;

        input {
          width: 100%;
          height: 100%;
          padding: 0 20px;
          outline: none;
          border: none;
          background-color: transparent;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          place-content: start;

          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
          }

          color: rgba(255, 255, 255, 0.6);
        }

        button {
          position: absolute;
          right: 5px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          width: 100px;
          height: 90%;
          border-radius: 5px;
          outline: none;
          border: none;
          background-image: linear-gradient(90.06deg, rgb(17, 169, 125) -5%, rgb(102, 16, 242) 142.89%);
          color: #fff;
          font-weight: bold;

          &:hover {
            cursor: pointer;
          }
        }
      }

    }

    .footer {
      width: 100%;
      height: 74px;
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div.f-top, div.f-bottom {
        height: 21px;
        line-height: 21px;
      }

      div.f-top {
        a {
          color: #fff;
          text-decoration: none;
          font-size: 14px;
        }
      }

      div.f-bottom {
        display: flex;
        justify-content: space-between;
        font-size: 14px;

        span {
          color: rgba(255, 255, 255, 0.6);
          margin: 0 20px;

          &:hover {
            color: #fff;
            cursor: pointer;
          }

          &:first-child {
            flex: 1;
            margin: 0;
          }
        }
      }

    }
  }
}
</style>